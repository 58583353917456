import { Outlet } from "react-router-dom";
import Spinner from "@/components/Spinner";
import Toaster from "@/components/Toaster";
import Dialog from "@/components/Dialog";
import DialogConfirmacao from "@/components/DialogConfirmacao";
import { useSelector } from "react-redux";
import {
  setInfoDialog,
  setInfoDialogConfirmacao,
} from "@/store/reducers/geral";

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const geral = useSelector((state) => state.geral);
  return (
    <>
      <Outlet />
      {geral?.infoDialog?.open && (
        <Dialog {...{ infoDialog: geral.infoDialog, setInfoDialog }} />
      )}
      <Spinner {...{ showSpinner: geral.showSpinner }}></Spinner>
      <Toaster {...{ infoToaster: geral.infoToaster }}></Toaster>
      {geral?.infoDialogConfirmacao.open && (
        <DialogConfirmacao
          {...{
            infoDialogConfirmacao: geral.infoDialogConfirmacao,
            setInfoDialogConfirmacao,
          }}
        />
      )}
    </>
  );
};

export default MinimalLayout;
