import { createTheme } from "@mui/material/styles";

const Palette = (mode) => {
  return createTheme({
    palette: {
      primary: {
        light: "#179b46",
        main: "#179b46",
        dark: "#179b46",
        contrastText: "#ffffff",
      },
    },
  });
};

export default Palette;
